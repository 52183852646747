.project-thumbnail {
	position: relative;
	background: white;
	background-size: 0 !important;

	.container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		min-height: 100vh;
		position: relative;

		@include media-breakpoint-down(md) {
			min-height: unset;
			display: block;
		}
	}

	.content {
		width: 40%;
		padding-right: 15px;

		h2 {
			font-size: 4rem;
			margin-left: -3px;

			@include media-breakpoint-down(md) {
				font-size: 3rem;
			}

			@include media-breakpoint-down(sm) {
				font-size: 2rem;
				margin-left: 0;
			}
		}

		h3 {
			font-size: 2rem;
		}

		p {
			text-transform: uppercase;
			font-weight: 500;
			letter-spacing: 1px;
		}

		@include media-breakpoint-down(md) {
			width: 100%;
			padding: 50px 0;
			z-index: 1;
			position: relative;
			color: white;
		}
	}

	.image {
		position: relative;
		width: 60%;
		margin: 0 auto;
		align-items: center;

		img {
			width: auto;
			border-radius: 5px;
			max-width: 100%;
			height: 500px;
			object-fit: cover;

			@include media-breakpoint-down(md) {
				display: none;
			}
		}
	}

	p.counter {
		position: absolute;
		z-index: 1;
		bottom: 50px;
		right: 50px;
		font-size: 10rem;
		color: black;
		font-weight: 700;
		-webkit-text-stroke-width: 2px;
		-moz-text-stroke-width: 2px;
		-webkit-text-fill-color: rgba(255, 255, 255, 0.5);
		-moz-text-fill-color: rgba(255, 255, 255, 0.5);
	}

	&:nth-of-type(odd) {
		background: #f8f9fc;
	}

	@include media-breakpoint-down(md) {
		margin-bottom: 5px;
		background: no-repeat center;
		-webkit-background-size: cover;
		background-size: cover !important;
		padding-top: 45px;

		&:after {
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background: rgba(0, 0, 0, 0.3);
		}
	}
}
