@keyframes gradient {
  0% {
    opacity: 0;
    background-position: 0 50%;
  }
  35% {
    opacity: 0.3;
  }
  75% {
    opacity: 0.3;
  }
  100% {
    opacity: 0;
    background-position: 100% 50%;
  }
}

@keyframes project-load-in-nav {
   from {
     opacity: 0;
     margin-top: -20px;
   }
   to {
     opacity: 1;
     margin-top: 0;
   }
 }

@keyframes project-load-in-content {
  from {width: 0;}
  to {width: 40%;}
}

@keyframes project-load-in-content-small {
  from {width: 0;}
  to {width: 55%;}
}

@keyframes project-load-in-content-mobile {
  0% {
    opacity: 0;
    transform: translateY(150px);
    pointer-events: none;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
    pointer-events: all;
  }
}

@keyframes project-load-in-image {
  from {width: 100%;}
  to {width: 60%;}
}

@keyframes project-load-in-image-small {
  from {width: 100%;}
  to {width: 45%;}
}

@keyframes project-load-in-image-text {
  from {opacity: 1;}
  to {opacity: 0;}
}

@keyframes project-load-in-text {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes project-load-bottom-bar {
  0% {
    opacity: 0;
    transform: translateY(150px);
    pointer-events: none;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
    pointer-events: all;
  }
}

@keyframes project-load-bottom-text {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes project-load-in-main-nav {
  from {
    opacity: 0;
    margin-top: 0;
  }
  to {
    opacity: 1;
    margin-top: 15px;
  }
}