.active {
  font-weight: bold;
}

.btn {
  background: white;
  border-radius: 5px;
  padding: 5px 10px;
  color: black;
  border: none;
  box-shadow: 1px 3px 5px 1px rgba(0,0,0,0.25);
  -webkit-box-shadow: 1px 3px 5px 1px rgba(0,0,0,0.25);
  -moz-box-shadow: 1px 3px 5px 1px rgba(0,0,0,0.25);
  transition: 350ms;

  &:hover {
    background: rgba(0,0,0, 0.8);
    color: white;
    cursor: pointer;
  }
}