section.projects {
  > .container {
    display: flex;
    align-items: center;
    justify-content: center;

    .row {
      width: 100%;
      text-align: center;

      h3 {
        font-size: 3rem;
        font-weight: 700;
        -webkit-text-stroke-width: 2px;
        -moz-text-stroke-width: 2px;
        -webkit-text-fill-color: rgba(255,255,255,0);
        -moz-text-fill-color: rgba(255,255,255,0);
        color: black;
      }
    }
  }
}