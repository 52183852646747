section.home {
  color: black;

  > .container {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .content {
      h3 {
        font-size: 1.5rem;
        font-weight: 400;
      }

      h1 {
        font-size: 7rem;
        line-height: 7rem;
        font-weight: 700;

        @include media-breakpoint-down(md) {
          font-size: 5rem;
          line-height: 6rem;
        }

        @include media-breakpoint-down(sm) {
          font-size: 4rem;
          line-height: 5rem;
        }

        @include media-breakpoint-down(xs) {
          font-size: 2.5rem;
          line-height: 3rem;
        }
      }

      p {
        max-width: 700px;
        font-size: 1.5rem;

        span {
          font-weight: 500;
          border-bottom: 1px solid black;

          &#google {
            padding-bottom: unset;
            border-bottom: none;

            span {
              padding-bottom: unset;
              border-bottom: none;

              &:first-of-type,
              &:nth-of-type(4){
                color: #517ef3;
              }

              &:nth-of-type(2),
              &:nth-of-type(6){
                color: #e5423c;
              }

              &:nth-of-type(3){
                color: #f7be1f;
              }

              &:nth-of-type(5){
                color: #3baa53;
              }

              &:hover {
                cursor: unset;
              }
            }

            &:hover {
              cursor: unset;
            }
          }
          
          &:hover {
            cursor: pointer;
          }
        }
        
        @include media-breakpoint-down(xs) {
          max-width: unset;
        }
      }
    }
  }

  #wizard-img {
    opacity: 0;
    pointer-events: none;
    height: auto;
    max-width: 400px;
    width: 100%;
    position: absolute;
    right: 25px;
    bottom: 0;
    z-index: 1;
    display: block;
    transition: opacity 350ms;

    &.show {
      opacity: 1;
    }

    @include media-breakpoint-down(lg) {
      max-width: 300px;
    }

    @include media-breakpoint-down(md) {
      max-width: 250px;
    }

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  #wizard-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    display: none;
  }

  &.magic {
    .main-nav {
      background: transparent;
    }

    #wizard-overlay {
      display: block;
      background: radial-gradient(circle, rgba(202, 37, 187, 0.8631827731092436) 0%, rgba(112, 34, 176, 1) 31%, rgba(31, 146, 225, 1) 72%, rgba(27, 240, 241, 1) 100%);
      background-size: 400% 400%;
      animation: gradient 3s ease infinite;
    }
  }
}
