section.about {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: black;
  overflow: hidden;

  > .container {
    display: flex;
    align-items: center;
  }

  nav.main-nav {
    background: transparent;
  }

  .content {
    display: flex;
    flex-direction: column;
    padding: 50px;
    max-width: 55%;
    width: 100%;
    z-index: 1;
    position: relative;

    p {
      font-size: 1.125rem;
    }

    .person {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      img {
        width: 250px;
        height: 250px;
        border-radius: 100%;
        object-fit: contain;

        @include media-breakpoint-down(lg) {
          width: 150px;
          height: 150px;
        }
      }

      .info {
        p {
          font-size: 1.5rem;
        }

        .mail {
          display: flex;
          align-items: center;
          justify-content: flex-start;

          i {
            font-size: 1.5rem;
          }

          p {
            font-size: 1.125rem;
          }
        }

        .socials {
          display: flex;
          align-items: center;
          justify-content: flex-start;

          a {
            i {
              font-size: 2rem;
              color: #6F3D8E;
            }

            &:nth-of-type(2) i {
              color: #2860C1;
            }
          }
        }

        @include media-breakpoint-down(xs) {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }

      @include media-breakpoint-down(xs) {
        flex-direction: column;
        text-align: center;
      }
    }

    p.quote {
      font-size: 1.5rem;
      max-width: 650px;
      width: 100%;
      padding-right: 10px;

      @include media-breakpoint-down(lg) {
        font-size: 1.25rem;
      }
    }

    @include media-breakpoint-down(sm) {
      max-width: unset;
      background: rgba(255,255,255, 0.9);
      height: 100%;
      display: flex;
      justify-content: center;
    }
  }

  .image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    transform: skewX(-15deg);
    width: 45%;

    .inner {
      position: relative;
      transform: skewX(15deg);
      padding: 50px 50px 50px 0;
      z-index: 1;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        height: 90%;
        opacity: 0.9;
        transition: opacity 500ms;

        @include media-breakpoint-down(lg) {
          height: 70%;
        }
      }

      @include media-breakpoint-down(lg) {
        padding: 50px 15px 50px 0;
      }

      @include media-breakpoint-down(sm) {
        transform: unset;
        background: #bdefe3;
      }
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: -100%;
      bottom: 0;
      left: 0;
      background: #bdefe3;

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
      transform: unset;
      display: none;
    }
  }

  @include media-breakpoint-down(sm) {
    overflow-y: scroll;
    background: url("https://jordeyknook.nl/img/visuals-colored.png") no-repeat center / 90% #bdefe3;
  }

  @include media-breakpoint-down(xs) {
    text-align: center;
  }
}
