*,
::after,
::before {
    box-sizing: border-box
}

button:not(:disabled) {
    cursor: pointer
}

.collapse:not(.show) {
    display: none
}

.collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height .35s ease
}

button:not(:disabled) {
    cursor: pointer
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto
}

@media (min-width:576px) {
    .container {
        max-width: 540px
    }
}

@media (min-width:768px) {
    .container {
        max-width: 720px
    }
}

@media (min-width:992px) {
    .container {
        max-width: 960px
    }
}

@media (min-width:1200px) {
    .container {
        max-width: 1140px
    }
}

.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto
}

@media (min-width:576px) {
    .container,
    .container-sm {
        max-width: 540px
    }
}

@media (min-width:768px) {
    .container,
    .container-md,
    .container-sm {
        max-width: 720px
    }
}

@media (min-width:992px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm {
        max-width: 960px
    }
}

@media (min-width:1200px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
        max-width: 1140px
    }
}

.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px
}

.no-gutters {
    margin-right: 0;
    margin-left: 0
}

.no-gutters>.col,
.no-gutters>[class*=col-] {
    padding-right: 0;
    padding-left: 0
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px
}

.col-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%
}

.col-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%
}

.col-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%
}

.col-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%
}

.col-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%
}

.col-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%
}

.col-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%
}

.col-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%
}

.col-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%
}

.col-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%
}

.col-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%
}

.col-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%
}

.offset-1 {
    margin-left: 8.333333%
}

.offset-2 {
    margin-left: 16.666667%
}

.offset-3 {
    margin-left: 25%
}

.offset-4 {
    margin-left: 33.333333%
}

.offset-5 {
    margin-left: 41.666667%
}

.offset-6 {
    margin-left: 50%
}

.offset-7 {
    margin-left: 58.333333%
}

.offset-8 {
    margin-left: 66.666667%
}

.offset-9 {
    margin-left: 75%
}

.offset-10 {
    margin-left: 83.333333%
}

.offset-11 {
    margin-left: 91.666667%
}

@media (min-width:576px) {
    .col-sm-1 {
        -ms-flex: 0 0 8.333333%;
        flex: 0 0 8.333333%;
        max-width: 8.333333%
    }
    .col-sm-2 {
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%
    }
    .col-sm-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%
    }
    .col-sm-4 {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%
    }
    .col-sm-5 {
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%
    }
    .col-sm-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%
    }
    .col-sm-7 {
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%
    }
    .col-sm-8 {
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%
    }
    .col-sm-9 {
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%
    }
    .col-sm-10 {
        -ms-flex: 0 0 83.333333%;
        flex: 0 0 83.333333%;
        max-width: 83.333333%
    }
    .col-sm-11 {
        -ms-flex: 0 0 91.666667%;
        flex: 0 0 91.666667%;
        max-width: 91.666667%
    }
    .col-sm-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%
    }
    .offset-sm-0 {
        margin-left: 0
    }
    .offset-sm-1 {
        margin-left: 8.333333%
    }
    .offset-sm-2 {
        margin-left: 16.666667%
    }
    .offset-sm-3 {
        margin-left: 25%
    }
    .offset-sm-4 {
        margin-left: 33.333333%
    }
    .offset-sm-5 {
        margin-left: 41.666667%
    }
    .offset-sm-6 {
        margin-left: 50%
    }
    .offset-sm-7 {
        margin-left: 58.333333%
    }
    .offset-sm-8 {
        margin-left: 66.666667%
    }
    .offset-sm-9 {
        margin-left: 75%
    }
    .offset-sm-10 {
        margin-left: 83.333333%
    }
    .offset-sm-11 {
        margin-left: 91.666667%
    }
}

@media (min-width:768px) {
    .col-md-1 {
        -ms-flex: 0 0 8.333333%;
        flex: 0 0 8.333333%;
        max-width: 8.333333%
    }
    .col-md-2 {
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%
    }
    .col-md-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%
    }
    .col-md-4 {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%
    }
    .col-md-5 {
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%
    }
    .col-md-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%
    }
    .col-md-7 {
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%
    }
    .col-md-8 {
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%
    }
    .col-md-9 {
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%
    }
    .col-md-10 {
        -ms-flex: 0 0 83.333333%;
        flex: 0 0 83.333333%;
        max-width: 83.333333%
    }
    .col-md-11 {
        -ms-flex: 0 0 91.666667%;
        flex: 0 0 91.666667%;
        max-width: 91.666667%
    }
    .col-md-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%
    }
    .offset-md-0 {
        margin-left: 0
    }
    .offset-md-1 {
        margin-left: 8.333333%
    }
    .offset-md-2 {
        margin-left: 16.666667%
    }
    .offset-md-3 {
        margin-left: 25%
    }
    .offset-md-4 {
        margin-left: 33.333333%
    }
    .offset-md-5 {
        margin-left: 41.666667%
    }
    .offset-md-6 {
        margin-left: 50%
    }
    .offset-md-7 {
        margin-left: 58.333333%
    }
    .offset-md-8 {
        margin-left: 66.666667%
    }
    .offset-md-9 {
        margin-left: 75%
    }
    .offset-md-10 {
        margin-left: 83.333333%
    }
    .offset-md-11 {
        margin-left: 91.666667%
    }
}

@media (min-width:992px) {
    .col-lg-1 {
        -ms-flex: 0 0 8.333333%;
        flex: 0 0 8.333333%;
        max-width: 8.333333%
    }
    .col-lg-2 {
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%
    }
    .col-lg-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%
    }
    .col-lg-4 {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%
    }
    .col-lg-5 {
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%
    }
    .col-lg-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%
    }
    .col-lg-7 {
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%
    }
    .col-lg-8 {
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%
    }
    .col-lg-9 {
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%
    }
    .col-lg-10 {
        -ms-flex: 0 0 83.333333%;
        flex: 0 0 83.333333%;
        max-width: 83.333333%
    }
    .col-lg-11 {
        -ms-flex: 0 0 91.666667%;
        flex: 0 0 91.666667%;
        max-width: 91.666667%
    }
    .col-lg-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%
    }

    .offset-lg-0 {
        margin-left: 0
    }
    .offset-lg-1 {
        margin-left: 8.333333%
    }
    .offset-lg-2 {
        margin-left: 16.666667%
    }
    .offset-lg-3 {
        margin-left: 25%
    }
    .offset-lg-4 {
        margin-left: 33.333333%
    }
    .offset-lg-5 {
        margin-left: 41.666667%
    }
    .offset-lg-6 {
        margin-left: 50%
    }
    .offset-lg-7 {
        margin-left: 58.333333%
    }
    .offset-lg-8 {
        margin-left: 66.666667%
    }
    .offset-lg-9 {
        margin-left: 75%
    }
    .offset-lg-10 {
        margin-left: 83.333333%
    }
    .offset-lg-11 {
        margin-left: 91.666667%
    }
}

@media (min-width:1200px) {
    .offset-xl-0 {
        margin-left: 0;
    }
}

.m-0 {
    margin: 0!important
}

.mt-0 {
    margin-top: 0!important
}

.mr-0 {
    margin-right: 0!important
}

.mb-0 {
    margin-bottom: 0!important
}

.ml-0 {
    margin-left: 0!important
}

.m-1 {
    margin: .25rem!important
}

.mt-1 {
    margin-top: .25rem!important
}

.mr-1 {
    margin-right: .25rem!important
}

.mb-1 {
    margin-bottom: .25rem!important
}

.ml-1 {
    margin-left: .25rem!important
}

.m-2 {
    margin: .5rem!important
}

.mt-2 {
    margin-top: .5rem!important
}

.mr-2 {
    margin-right: .5rem!important
}

.mb-2 {
    margin-bottom: .5rem!important
}

.ml-3 {
    margin-left: .5rem!important
}

.m-3 {
    margin: 1rem!important
}

.mt-3 {
    margin-top: 1rem!important
}

.mr-3 {
    margin-right: 1rem!important
}

.mb-3 {
    margin-bottom: 1rem!important
}

.ml-3 {
    margin-left: 1rem!important
}

.m-4 {
    margin: 1.5rem!important
}

.mt-4 {
    margin-top: 1.5rem!important
}

.mr-4 {
    margin-right: 1.5rem!important
}

.mb-4 {
    margin-bottom: 1.5rem!important
}

.ml-5 {
    margin-left: 1.5rem!important
}

.m-5 {
    margin: 3rem!important
}

.mt-5 {
    margin-top: 3rem!important
}

.mr-5 {
    margin-right: 3rem!important
}

.mb-5 {
    margin-bottom: 3rem!important
}

.ml-5 {
    margin-left: 3rem!important
}

.p-0 {
    padding: 0!important
}

.pt-0 {
    padding-top: 0!important
}

.pr-0 {
    padding-right: 0!important
}

.pb-0 {
    padding-bottom: 0!important
}

.pl-0 {
    padding-left: 0!important
}

.p-1 {
    padding: .25rem!important
}

.pt-1 {
    padding-top: .25rem!important
}

.pr-1 {
    padding-right: .25rem!important
}

.pb-1 {
    padding-bottom: .25rem!important
}

.pl-1 {
    padding-left: .25rem!important
}

.p-2 {
    padding: .5rem!important
}

.pt-2 {
    padding-top: .5rem!important
}

.pr-2 {
    padding-right: .5rem!important
}

.pb-2 {
    padding-bottom: .5rem!important
}

.pl-2 {
    padding-left: .5rem!important
}

.p-3 {
    padding: 1rem!important
}

.pt-3 {
    padding-top: 1rem!important
}

.pr-3 {
    padding-right: 1rem!important
}

.pb-3 {
    padding-bottom: 1rem!important
}

.pl-3 {
    padding-left: 1rem!important
}

.p-4 {
    padding: 1.5rem!important
}

.pt-4 {
    padding-top: 1.5rem!important
}

.pr-4 {
    padding-right: 1.5rem!important
}

.pb-4 {
    padding-bottom: 1.5rem!important
}

.pl-4 {
    padding-left: 1.5rem!important
}

.p-5 {
    padding: 3rem!important
}

.pt-5 {
    padding-top: 3rem!important
}

.pr-5 {
    padding-right: 3rem!important
}

.pb-5 {
    padding-bottom: 3rem!important
}

.pl-5 {
    padding-left: 3rem!important
}

@media (min-width:576px) {
    .m-sm-0 {
        margin: 0!important
    }
    .mt-sm-0 {
        margin-top: 0!important
    }
    .mr-sm-0 {
        margin-right: 0!important
    }
    .mb-sm-0 {
        margin-bottom: 0!important
    }
    .ml-sm-0 {
        margin-left: 0!important
    }
    .m-sm-1 {
        margin: .25rem!important
    }
    .mt-sm-1 {
        margin-top: .25rem!important
    }
    .mr-sm-1 {
        margin-right: .25rem!important
    }
    .mb-sm-1 {
        margin-bottom: .25rem!important
    }
    .ml-sm-1 {
        margin-left: .25rem!important
    }
    .m-sm-2 {
        margin: .5rem!important
    }
    .mt-sm-2 {
        margin-top: .5rem!important
    }
    .mr-sm-2 {
        margin-right: .5rem!important
    }
    .mb-sm-2 {
        margin-bottom: .5rem!important
    }
    .ml-sm-2 {
        margin-left: .5rem!important
    }
    .m-sm-3 {
        margin: 1rem!important
    }
    .mt-sm-3 {
        margin-top: 1rem!important
    }
    .mr-sm-3 {
        margin-right: 1rem!important
    }
    .mb-sm-3 {
        margin-bottom: 1rem!important
    }
    .ml-sm-3 {
        margin-left: 1rem!important
    }
    .m-sm-4 {
        margin: 1.5rem!important
    }
    .mt-sm-4 {
        margin-top: 1.5rem!important
    }
    .mr-sm-4 {
        margin-right: 1.5rem!important
    }
    .mb-sm-4 {
        margin-bottom: 1.5rem!important
    }
    .ml-sm-4 {
        margin-left: 1.5rem!important
    }
    .m-sm-5 {
        margin: 3rem!important
    }
    .mt-sm-5 {
        margin-top: 3rem!important
    }
    .mr-sm-5 {
        margin-right: 3rem!important
    }
    .mb-sm-5 {
        margin-bottom: 3rem!important
    }
    .ml-sm-5 {
        margin-left: 3rem!important
    }
    .p-sm-0 {
        padding: 0!important
    }
    .pt-sm-0 {
        padding-top: 0!important
    }
    .pr-sm-0 {
        padding-right: 0!important
    }
    .pb-sm-0 {
        padding-bottom: 0!important
    }
    .pl-sm-0 {
        padding-left: 0!important
    }
    .p-sm-1 {
        padding: .25rem!important
    }
    .pt-sm-1 {
        padding-top: .25rem!important
    }
    .pr-sm-1 {
        padding-right: .25rem!important
    }
    .pb-sm-1 {
        padding-bottom: .25rem!important
    }
    .pl-sm-1 {
        padding-left: .25rem!important
    }
    .p-sm-2 {
        padding: .5rem!important
    }
    .pt-sm-2 {
        padding-top: .5rem!important
    }
    .pr-sm-2 {
        padding-right: .5rem!important
    }
    .pb-sm-2 {
        padding-bottom: .5rem!important
    }
    .pl-sm-2 {
        padding-left: .5rem!important
    }
    .p-sm-3 {
        padding: 1rem!important
    }
    .pt-sm-3 {
        padding-top: 1rem!important
    }
    .pr-sm-3 {
        padding-right: 1rem!important
    }
    .pb-sm-3 {
        padding-bottom: 1rem!important
    }
    .pl-sm-3 {
        padding-left: 1rem!important
    }
    .p-sm-4 {
        padding: 1.5rem!important
    }
    .pt-sm-4 {
        padding-top: 1.5rem!important
    }
    .pr-sm-4 {
        padding-right: 1.5rem!important
    }
    .pb-sm-4 {
        padding-bottom: 1.5rem!important
    }
    .pl-sm-4 {
        padding-left: 1.5rem!important
    }
    .p-sm-5 {
        padding: 3rem!important
    }
    .pt-sm-5 {
        padding-top: 3rem!important
    }
    .pr-sm-5 {
        padding-right: 3rem!important
    }
    .pb-sm-5 {
        padding-bottom: 3rem!important
    }
    .pl-sm-5 {
        padding-left: 3rem!important
    }
}

@media (min-width:768px) {
    .m-md-0 {
        margin: 0!important
    }
    .mt-md-0 {
        margin-top: 0!important
    }
    .mr-md-0 {
        margin-right: 0!important
    }
    .mb-md-0 {
        margin-bottom: 0!important
    }
    .ml-md-0 {
        margin-left: 0!important
    }
    .m-md-1 {
        margin: .25rem!important
    }
    .mt-md-1 {
        margin-top: .25rem!important
    }
    .mr-md-1 {
        margin-right: .25rem!important
    }
    .mb-md-1 {
        margin-bottom: .25rem!important
    }
    .ml-md-1 {
        margin-left: .25rem!important
    }
    .m-md-2 {
        margin: .5rem!important
    }
    .mt-md-2 {
        margin-top: .5rem!important
    }
    .mr-md-2 {
        margin-right: .5rem!important
    }
    .mb-md-2 {
        margin-bottom: .5rem!important
    }
    .ml-md-2 {
        margin-left: .5rem!important
    }
    .m-md-3 {
        margin: 1rem!important
    }
    .mt-md-3 {
        margin-top: 1rem!important
    }
    .mr-md-3 {
        margin-right: 1rem!important
    }
    .mb-md-3 {
        margin-bottom: 1rem!important
    }
    .ml-md-3 {
        margin-left: 1rem!important
    }
    .m-md-4 {
        margin: 1.5rem!important
    }
    .mt-md-4 {
        margin-top: 1.5rem!important
    }
    .mr-md-4 {
        margin-right: 1.5rem!important
    }
    .mb-md-4 {
        margin-bottom: 1.5rem!important
    }
    .ml-md-4 {
        margin-left: 1.5rem!important
    }
    .m-md-5 {
        margin: 3rem!important
    }
    .mt-md-5 {
        margin-top: 3rem!important
    }
    .mr-md-5 {
        margin-right: 3rem!important
    }
    .mb-md-5 {
        margin-bottom: 3rem!important
    }
    .ml-md-5 {
        margin-left: 3rem!important
    }
    .p-md-0 {
        padding: 0!important
    }
    .pt-md-0 {
        padding-top: 0!important
    }
    .pr-md-0 {
        padding-right: 0!important
    }
    .pb-md-0 {
        padding-bottom: 0!important
    }
    .pl-md-0 {
        padding-left: 0!important
    }
    .p-md-1 {
        padding: .25rem!important
    }
    .pt-md-1 {
        padding-top: .25rem!important
    }
    .pr-md-1 {
        padding-right: .25rem!important
    }
    .pb-md-1 {
        padding-bottom: .25rem!important
    }
    .pl-md-1 {
        padding-left: .25rem!important
    }
    .p-md-2 {
        padding: .5rem!important
    }
    .pt-md-2 {
        padding-top: .5rem!important
    }
    .pr-md-2 {
        padding-right: .5rem!important
    }
    .pb-md-2 {
        padding-bottom: .5rem!important
    }
    .pl-md-2 {
        padding-left: .5rem!important
    }
    .p-md-3 {
        padding: 1rem!important
    }
    .pt-md-3 {
        padding-top: 1rem!important
    }
    .pr-md-3 {
        padding-right: 1rem!important
    }
    .pb-md-3 {
        padding-bottom: 1rem!important
    }
    .pl-md-3 {
        padding-left: 1rem!important
    }
    .p-md-4 {
        padding: 1.5rem!important
    }
    .pt-md-4 {
        padding-top: 1.5rem!important
    }
    .pr-md-4 {
        padding-right: 1.5rem!important
    }
    .pb-md-4 {
        padding-bottom: 1.5rem!important
    }
    .pl-md-4 {
        padding-left: 1.5rem!important
    }
    .p-md-5 {
        padding: 3rem!important
    }
    .pt-md-5 {
        padding-top: 3rem!important
    }
    .pr-md-5 {
        padding-right: 3rem!important
    }
    .pb-md-5 {
        padding-bottom: 3rem!important
    }
    .pl-md-5 {
        padding-left: 3rem!important
    }
}

@media (min-width:992px) {
    .m-lg-0 {
        margin: 0!important
    }
    .mt-lg-0 {
        margin-top: 0!important
    }
    .mr-lg-0 {
        margin-right: 0!important
    }
    .mb-lg-0 {
        margin-bottom: 0!important
    }
    .ml-lg-0 {
        margin-left: 0!important
    }
    .m-lg-1 {
        margin: .25rem!important
    }
    .mt-lg-1 {
        margin-top: .25rem!important
    }
    .mr-lg-1 {
        margin-right: .25rem!important
    }
    .mb-lg-1 {
        margin-bottom: .25rem!important
    }
    .ml-lg-1 {
        margin-left: .25rem!important
    }
    .m-lg-2 {
        margin: .5rem!important
    }
    .mt-lg-2 {
        margin-top: .5rem!important
    }
    .mr-lg-2 {
        margin-right: .5rem!important
    }
    .mb-lg-2 {
        margin-bottom: .5rem!important
    }
    .ml-lg-2 {
        margin-left: .5rem!important
    }
    .m-lg-3 {
        margin: 1rem!important
    }
    .mt-lg-3 {
        margin-top: 1rem!important
    }
    .mr-lg-3 {
        margin-right: 1rem!important
    }
    .mb-lg-3 {
        margin-bottom: 1rem!important
    }
    .ml-lg-3 {
        margin-left: 1rem!important
    }
    .m-lg-4 {
        margin: 1.5rem!important
    }
    .mt-lg-4 {
        margin-top: 1.5rem!important
    }
    .mr-lg-4 {
        margin-right: 1.5rem!important
    }
    .mb-lg-4 {
        margin-bottom: 1.5rem!important
    }
    .ml-lg-4 {
        margin-left: 1.5rem!important
    }
    .m-lg-5 {
        margin: 3rem!important
    }
    .mt-lg-5 {
        margin-top: 3rem!important
    }
    .mr-lg-5 {
        margin-right: 3rem!important
    }
    .mb-lg-5 {
        margin-bottom: 3rem!important
    }
    .ml-lg-5 {
        margin-left: 3rem!important
    }
    .p-lg-0 {
        padding: 0!important
    }
    .pt-lg-0 {
        padding-top: 0!important
    }
    .pr-lg-0 {
        padding-right: 0!important
    }
    .pb-lg-0 {
        padding-bottom: 0!important
    }
    .pl-lg-0 {
        padding-left: 0!important
    }
    .p-lg-1 {
        padding: .25rem!important
    }
    .pt-lg-1 {
        padding-top: .25rem!important
    }
    .pr-lg-1 {
        padding-right: .25rem!important
    }
    .pb-lg-1 {
        padding-bottom: .25rem!important
    }
    .pl-lg-1 {
        padding-left: .25rem!important
    }
    .p-lg-2 {
        padding: .5rem!important
    }
    .pt-lg-2 {
        padding-top: .5rem!important
    }
    .pr-lg-2 {
        padding-right: .5rem!important
    }
    .pb-lg-2 {
        padding-bottom: .5rem!important
    }
    .pl-lg-2 {
        padding-left: .5rem!important
    }
    .p-lg-3 {
        padding: 1rem!important
    }
    .pt-lg-3 {
        padding-top: 1rem!important
    }
    .pr-lg-3 {
        padding-right: 1rem!important
    }
    .pb-lg-3 {
        padding-bottom: 1rem!important
    }
    .pl-lg-3 {
        padding-left: 1rem!important
    }
    .p-lg-4 {
        padding: 1.5rem!important
    }
    .pt-lg-4 {
        padding-top: 1.5rem!important
    }
    .pr-lg-4 {
        padding-right: 1.5rem!important
    }
    .pb-lg-4 {
        padding-bottom: 1.5rem!important
    }
    .pl-lg-4 {
        padding-left: 1.5rem!important
    }
    .p-lg-5 {
        padding: 3rem!important
    }
    .pt-lg-5 {
        padding-top: 3rem!important
    }
    .pr-lg-5 {
        padding-right: 3rem!important
    }
    .pb-lg-5 {
        padding-bottom: 3rem!important
    }
    .pl-lg-5 {
        padding-left: 3rem!important
    }
}