nav.main-nav {
	background: #f8f9fc;
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 2;
	padding: 5px 0;

	.container {
		display: flex;
		align-items: center;
	}

	ul {
		margin-right: auto;
		list-style-type: none;

		li {
			display: inline-block;
			padding: 8px 15px;
		}
	}

	a {
		color: black;
	}

	@include media-breakpoint-down(sm) {
		ul {
			margin: 0;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}
}
