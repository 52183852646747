section.project {
	overflow: hidden;
	display: flex;
	justify-content: space-between;
	align-items: center;
	z-index: 2;
	opacity: 0;
	animation: fadeIn 500ms 0.5s;
	animation-fill-mode: forwards;

	.content {
		width: 0;
		display: flex;
		flex-direction: column;
		min-height: 100vh;
		height: 100%;
		background: #fefefe;
		animation: project-load-in-content 500ms 1.5s ease-in-out;
		animation-fill-mode: forwards;

		.container {
			max-width: 80%;
			margin: 0 auto;

			.main-nav {
				position: relative;
				margin-top: -20px;
				opacity: 0;
				animation: project-load-in-nav 500ms 2.5s;
				animation-fill-mode: forwards;
				background: unset;

				ul {
					margin-left: unset;
					margin-right: auto;

					li:first-of-type {
						padding-left: 0;
					}
				}

				.container {
					max-width: 100%;
					padding: 0;
				}
			}

			@include media-breakpoint-down(sm) {
				max-width: unset;
			}
		}

		.about {
			opacity: 0;
			transform: translateY(50px);
			margin: auto 0;
			animation: project-load-in-text 500ms 2.5s;
			animation-fill-mode: forwards;

			.container {
				h2 {
					font-weight: 700;
					color: black;
					font-size: 5rem;

					@include media-breakpoint-down(lg) {
						font-size: 3.5rem;
					}

					@include media-breakpoint-down(sm) {
						font-size: 2rem;
					}
				}

				p {
					line-height: 1.75;

					&:first-of-type:hover {
						cursor: pointer;
					}

					&:nth-of-type(2) {
						text-transform: uppercase;
					}
				}
			}

			@include media-breakpoint-down(sm) {
				padding: 50px 0;
			}
		}

		.bottom-info {
			display: flex;
			flex-direction: column;
			transform: translateY(150px);
			width: 100%;
			color: white;
			opacity: 0;
			animation: project-load-bottom-bar 1s 3s;
			animation-fill-mode: forwards;
			background: lighten(#1a1a1a, 5);

			.container {
				display: flex;
				justify-content: space-between;
				padding: 30px 15px 20px 15px;

				@include media-breakpoint-down(sm) {
					flex-direction: column-reverse;
				}
			}

			b {
				font-weight: 500;
			}

			.tech {
				max-width: 50%;

				ul {
					li {
						position: relative;
						margin: 0 20px 10px 0;
						list-style-type: none;
						display: inline-block;

						&:after {
							content: '';
							margin: auto;
							height: 4px;
							width: 4px;
							position: absolute;
							top: 0;
							bottom: 0;
							right: -12px;
							background: white;
							border-radius: 100%;
						}

						&:last-of-type {
							margin-right: 0;

							&:after {
								display: none;
							}
						}
					}
				}

				@include media-breakpoint-down(sm) {
					max-width: unset;
					margin-bottom: 10px;
				}
			}

			.info p span {
				font-weight: 500;
				display: block;
				margin-bottom: 5px;
			}

			.visit {
				a {
					background: darken(#1a1a1a, 2);
					color: white;
					text-align: center;
					padding: 15px;
					width: 100%;
					transition: background 350ms;

					&:hover {
						background: darken(#1a1a1a, 5);
					}
				}
			}
		}

		@include media-breakpoint-down(lg) {
			animation: project-load-in-content-small 500ms 1.5s ease-in-out;
			animation-fill-mode: forwards;
		}

		@include media-breakpoint-down(sm) {
			animation: project-load-in-content-mobile 500ms 1.5s ease-in-out;
			animation-fill-mode: forwards;
			position: absolute;
			bottom: 0;
			width: 100%;
			opacity: 0;
			transform: translateY(150px);
			pointer-events: none;
			z-index: 1;
			background: transparentize(#fefefe, 0.1);
		}
	}

	.image {
		position: relative;
		width: 100%;
		background: no-repeat center / cover;
		animation: project-load-in-image 1s 1.5s;
		animation-fill-mode: forwards;

		.container {
			min-height: 100vh;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: flex-start;

			h2 {
				font-size: 10rem;
				color: white;
				text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
				animation: project-load-in-image-text 350ms 1.25s;
				animation-fill-mode: forwards;

				@include media-breakpoint-down(md) {
					font-size: 5rem;
				}

				@include media-breakpoint-down(sm) {
					font-size: 2.5rem;
				}
			}
		}

		&:before {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background: transparentize(black, 0.8);
		}

		@include media-breakpoint-down(lg) {
			animation: project-load-in-image-small 1s 1.5s;
			animation-fill-mode: forwards;
		}

		@include media-breakpoint-down(sm) {
			animation: none;
		}
	}

	@include media-breakpoint-down(sm) {
		overflow-y: scroll;
	}
}
