* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Roboto', sans-serif;
}

body {
	margin: 0;
}

p,
a {
	font-weight: 400;
}

a {
	text-decoration: none;
	display: inline-block;
}

section {
	position: relative;

	&.page {
		position: absolute;
		min-height: 100vh;
		height: 100%;
		width: 100%;
		background: #f8f9fc;

		> .container {
			min-height: 100vh;
			height: 100%;
		}
	}
}
