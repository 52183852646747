.project-list {
  > .container {
    display: flex;
    align-items: center;
  }

  .banner {
    padding: 50px 0;
    text-align: center;
    background: #F8F9FC;

    p {
      font-size: 1.25rem;
    }

    .btn {
      max-width: 250px;
      margin: 0 auto;
    }
  }
}